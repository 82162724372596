.authPage-mobile {
  padding: 20px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  .signInHeader-mobile {
    img {
      max-height: 70px;
    }
    .auth-top{
      &-heading{
        // font-size: 32px;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
      &-desc{
        font-size: 13px;
        line-height: 18px;
        color: #ccc;
        margin-top: 4px;
        text-transform: capitalize;
      }
    }
    .authBtnGroup-mobile {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    .authBtn-active-mobile,
    .authBtn-mobile {
      font-weight: 500;
      font-size: 18px;
      width: 100%;
      border-radius: 5px;
    }
  }
  .authForm-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    .input-group {
      display: flex;
      flex-direction: column;
      &:not(:first-child){
        margin-top: 16px;
      }
      .input-label{
        font-size: 12px;
        color: #fff;
        margin-bottom: 6px;
      }
    }
    .input-control {
      border: 1px solid #fff;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      padding: 12px;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--ion-color-dark-contrast) inset !important;
        // -webkit-text-fill-color: var(--ion-color-primary);
      }
    }
    .signbtn {
      font-size: 18px;
      font-weight: 700;
      height: 56px;
      margin-top: 32px;
      text-transform: capitalize;
    }
    .error {
      font-size: 12px;
      line-height: 18px;
    }
    .signWithSocial-mobile {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .socialbtn {
        background: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 32px;
        text-transform: unset;
        .socialicon {
          margin-left: 2px;
          width: 15px;
        }
      }
      ion-button {
        flex: 1 1 50%;
      }
    }
    .forgotPassword{
      color: #fff;
      text-align: right;
      font-size: 12px;
      text-transform: capitalize;
      margin-top: 16px;
    }
    .auth-toggler{
      color: #fff;
      text-align: center;
      font-size: 12px;
      text-transform: capitalize;
      margin: 16px 0;
      span{
        font-weight: 700;
        color: var(--ion-color-primary);
      }
    }
    .privacypolicy {
      display: flex;
      align-items: center;
      a { 
        color: var(--ion-color-white);
        font-weight: 600;
        font-size: 12px;
        // margin-left: 12px;
        margin-left: 4px;

      }
      .aa {
        color: var(--ion-color-primary);
        font-weight: 600;
        font-size: 12px;
        margin-left: 0px;
      }
      input[type="checkbox"]{
        box-shadow: none;
      }
      input[type="checkbox"]:checked {
        accent-color: var(--ion-color-primary);
      }
    }
    .countrycode {
      background-color: transparent;
      border: 2px solid var(--ion-color-primary);
      min-width: 160px;
      outline: 0;
      font-size: 12px;
      font-weight: 700;
      border-radius: 4px;
      .css-yk16xz-control,
      .css-1pahdxg-control {
        background-color: transparent;
        border: none;
        outline: 0;
        box-shadow: none;
        .css-1wa3eu0-placeholder {
          color: var(--ion-color-primary);
          font-size: 12px;
        }
        .css-1uccc91-singleValue {
          color: var(--ion-color-primary);
        }
        .css-1okebmr-indicatorSeparator { 
          background-color: var(--ion-color-primary);
        }
        .css-tj5bde-Svg {
          fill: var(--ion-color-primary);
        }
        .css-b8ldur-Input {
          color: var(--ion-color-primary);
        }
      }
      .css-26l3qy-menu {
        height: 160px;
        overflow: hidden;
      }
    }
    .numbercode-wrap {
      .countrycode {
        background-color: transparent;
        // color: rgba(239, 207, 2, 0.9);
        border: none;
        border-right: 2px solid var(--ion-color-primary);
        min-width: 160px;
        outline: 0;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        border-radius: 0;
      }
      .input-control {
        padding-left: 170px;
      }
    }
  }
}
