.genrelist {
  box-sizing: border-box;
  .movie-card {
    .cardlinking {
      min-width: unset;
      flex: 0 0 50%;
      box-sizing: border-box;
      &:nth-child(2n-1){
        padding-right: 6px;
      }
      &:nth-child(2n){
        padding-left: 6px;
      }
      &:nth-child(n+3){
        padding-top: 12px;
      }
      .movie-short{
        .flag{
          display: flex;
          padding: 4px 0px 2px;
        }
        h5{
          font-size: 12px;
          // line-height: 16px;
          // margin-top: 16px;
          margin-top: 8px;

        }
      }
    }
  }
}
.container{
position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 20px;
}

// .genrelist {
//   box-sizing: border-box;
//   display: flex;
//   flex-wrap: wrap;
// }

// .movie-card {
//   box-sizing: border-box;
//   width: 50%;
//   padding-right: 6px;
//   padding-left: 6px;
//   padding-top: 12px;
// }

// .movie-card:nth-child(2n) {
//   padding-left: 6px;
//   padding-right: 0;
// }

// .movie-card:nth-child(n+3) {
//   padding-top: 12px;
// }

// .cardlinking {
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
// }

// .movie-short {
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

// .flag {
//   display: flex;
//   padding: 4px 0 2px;
// }

// h5 {
//   font-size: 12px;
//   line-height: 16px;
//   text-align: center;
// }

// @media only screen and (max-width: 768px) {
//   .movie-card {
//     width: 100%;
//     padding-right: 0;
//     padding-left: 0;
//   }
// }

