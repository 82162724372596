@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

.modal-dialog{
      // height: 100vh;
      height: 96vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.planpayment {
  .modal-content {
    // min-height: 410px;
    // background-color: var(--ion-color-dark);
    // color: var(--ion-color-dark-contrast);
    // @media (max-width: 768px) {
    //   min-height: calc(100vh - 20px);
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color:var(--ion-color-dark-shade);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    }
  }

.planpayment-popup {
  color: var(--ion-color-dark-dark);
  background-color: #fff;
  // background-color:var(--ion-color-dark-shade);
  // padding: 12px 12px 0;
  padding: 24px 12px 24px;
    width: 100%;
    text-align: center;
    border-radius: 12px;
  .main-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    // color: var(--ion-color-primary);
    color: var(--ion-color-black);
    text-transform: capitalize;
    &-sub {
      font-size: 14px;
      line-height: 20px;
      margin-top: 12px;
      font-weight: 400;
      // color:var(--ion-color-white);
      color: #B0B0B0;
      // text-align: left;
      text-align: center;
      padding: 0 32px 0 32px;
    }
  }
      .resend-code{
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      // color: var(--ion-color-white);
      color: var(--ion-color-primary);
      @media (max-width: 767px) {
        font-size: 12px;
      line-height: 16px;
      padding: 4px 14px 0 0;
      }
    }
    .action-row{
      // margin-top: 16px;
      margin-top: 0px;
      .btnprimary{
        // font-weight: 400;
        font-weight: 700;
        margin-top: 14px;
        width: 86%;
        --box-shadow: none;
        text-transform: capitalize;
      }
      .btntransparent{
        color: var(--ion-color-primary);
        --box-shadow: none;
        text-transform: capitalize;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  .plans-container {
    width: 100%;
    .plan-heading {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 12px;
      // margin-top: 24px;
      // color: var(--ion-color-primary);
      color: var(--ion-color-black);
    }
    .plan-heading-sub {
      font-size: 12px;
      line-height: 20px;
      // margin-top: 12px;
      font-weight: 400;
      // color: var(--ion-color-primary-contrast);
      color: #B0B0B0;
      margin-bottom: 12px;
    }
    .plan-box-wrap {
      display: flex;
      grid-gap: 16px;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        grid-gap: 0;
      }
      .plan-box {
        // border: 1px solid var(--ion-color-primary);
        // padding: 8px 16px;
        // border-radius: 12px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // flex: 1 1 50%;
        // transition: all 0.3s ease-in;
        border: 0px solid var(--ion-color-primary);
        // padding: 16px;
        padding: 18px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        transition: all 0.3s ease-in;
        background-color: var(--ion-color-dark);
        @media (max-width: 767px) {
          flex: 1 1 100%;
          &:not(:first-child){
            margin-top: 16px;
          }
        }
        .pricing {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: var(--ion-color-white);
          text-align: left;
          span {
            color: var(--ion-color-primary);
            display: block;
            text-align: left;
            margin-top: 14px;
            font-size: 30px;
          }
        }
        ion-button {
          margin-top: 20px;
          text-transform: capitalize;
          font-family: inter, sans-serif;
          font-size: 16px;
          font-weight: 700;
        }
        &:hover {
          box-shadow: var(--ion-color-primary);
        }
      }
    }
  }
  .disclaimer {
    font-size: 12px;
    // line-height: 14px;
    margin-top: 12px;
    font-weight: 200;
    // color:var(--ion-color-primary-contrast);
    color: #B0B0B0;
  }
  .align-center{
    ion-button{
      // padding-left: 34px;
      // padding-right: 34px;
      padding-left: 18px;
      padding-right: 18px;
      text-transform: capitalize;
      font-family: inter, sans-serif;
      font-size: 16px;
      font-weight: 700;
      --box-shadow:none;
    }
  }

}
.otp {
  // width: 100%;
  width: 92%;
  margin-bottom: 6px;
  border: 1px solid var(--ion-color-dark);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  padding: 10px;
  outline: none;
  margin-top: 10px;
  letter-spacing: 20px;
  text-align: left;
  &::placeholder {
    // font-weight: 500;
    // font-size: 14px;
    color:#cecece;
    letter-spacing: 1px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--ion-color-dark) inset !important;
    -webkit-text-fill-color: var(--ion-color-primary);
  }
}
