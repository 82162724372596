@import "../../../../../src/assets/css/themes/normal.scss";

.movie-payment {
  display: flex;
  flex-direction: column;
  // align-items: center;
  z-index: 200;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  .page-main-img{
    img{
      width: 100%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .payment-content{
    padding: 24px;
  }
  .payment-heading{
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .movie-payment-methods {
    max-width: 50%;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .payment-btn-active {
    width: 100%;
    margin-top: 32px;
    background-color: transparent;
    border: 2px solid var(--ion-color-primary);
    border-radius: 10px;
    height: 70px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: var(--ion-color-primary);
    cursor: pointer;
    transition: all ease-in 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus {
      background-color: var(--ion-color-primary);
      color: #000;
      outline: 0;
    }
    img{
      max-width: 50px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

.subscription-wrap{
  color: var(--ion-color-dark-contrast);
  @media (max-width: 767px) {
      padding: 24px;
  }
  .page-main-img{
    img{
      width: 100%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .content-section{
    padding: 32px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .subscription-heading{
    font-size: 36px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 16px;
  }
  .subscription-desc{
    font-size: 14px;
    line-height: 24px;
    margin-top: 36px;
    font-weight: 400;
  }
  .subscription-desc-row{
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 8px;
    margin-bottom: 1rem;
  }
  .plans-container {
    .plan-heading {
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .plan-box-wrap {
      display: flex;
      grid-gap: 16px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 50%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        grid-gap: 0;
      }
      .plan-box {
        border: 0px solid var(--ion-color-primary);
        padding: 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        flex: 1 1 50%;
        transition: all 0.3s ease-in;
        background-color: #1F1D33;
        @media (max-width: 767px) {
          &:not(:first-child){
            margin-top: 16px;
          }
        }
        .pricing {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          // white-space: nowrap;
          // span {
          //   color: var(--ion-color-secondary);
          //   font-size: 20px;
          //   line-height: 28px;
          // }
        }
        .pricing-amount {
          font-size: 30px;
          line-height: 30px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: .25rem;
          color: var(--ion-color-primary);

          // white-space: nowrap;
          // span {
          //   color: var(--ion-color-secondary);
          //   font-size: 20px;
          //   line-height: 28px;
          // }
        }
        ion-button {
          margin-top: 16px;
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 700;
        }
        &:hover {
          box-shadow: 2px 2px 4px rgba(#efcf03, 0.8);
        }
      }
    }
  }
  .disclaimer {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
    font-weight: 300;
    text-align: center;
  }
}