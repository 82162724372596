@import "../assets/css/vars.scss";
@import "../assets/css/themes/normal.scss";
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


html {
  min-height: 100%;
}
body {
  cursor: default !important;
  height: 100%;
}
html, body, * {
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
// ion-app{
//   min-height: 100vh;
// }
.customTab {
  display: flex;
  justify-content: space-around;
  .customTabButton {
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: $brand-main;
  }
  .customTabButton-active {
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: $brand-main;
  }
}
.sideMenuHeader {
  color: #8de4af;
  background: #8de4af;
}
.movie-card .movie {
  margin: 10px;
}
.movie-card .movie-short {
  margin: 5px;
}
.ionPage {
  z-index: 1 !important;
  background-color: $back-black;
}
.sc-ion-label-md-h {
  font-size: 12px;
}
.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

// .ionPage {
//   ion-content {
//     --offset-top: -57px !important;
//   }
//   &.video-native, &.authentication{
//     ion-content{
//       --offset-top: 0px !important;
//     }
//   }
// }

.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}

* {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
  width: 0 !important;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
}
// ion-tab-bar{
//   --ion-background-color: var(--ion-color-dark1) !important;
//   --background: var(--ion-color-dark1) !important;
// }
ion-header,
.header-ios,
ion-toolbar,
ion-tab-bar {
  --ion-background-color: var(--ion-color-dark) !important;
  --background: var(--ion-color-dark) !important;
}
ion-tab-bar {
  box-shadow: 0px 0px 3px #000000;
}
ion-content {
  --ion-background-color: var(--ion-color-dark);
}
ion-icon,
ion-label {
  color: var( --ion-color-white);
}
ion-icon {
  font-size: 20px;
}
ion-label {
  font-size: 12px;
}
.form-control {
  font-size: 14px;
  line-height: 20px;
  &::placeholder {
    font-size: 14px;
    line-height: 20px;
  }
}
.children-wrapper{
  min-height: calc(100vh - 123px);
  @media (max-width: 768px) {
    padding-bottom: 60px;
  }
}
.badge{
  &-primary{
    background-color: var(--ion-color-secondary);
  }
  &.absolute{
    position: absolute;
    right: 8px;
    top: -4px;
  }
}

.payment-success-section{
  padding: 32px;
  text-align: center;
  .payment-heading{
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  ion-button{
    max-width: 200px;
    margin-top: 32px;
  }
}