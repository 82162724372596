@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

.SeriesInfoContainer {
  .series-header {
    padding: 12px;
    padding-top: 0;
    .title {
      margin-top: 16px;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: var(--ion-color-white);
    }
    .series-detail-wrap-1 {
      margin: 0;
      padding: 0;
      display: flex;
      margin-top: 10px;
      overflow: auto;
      .series-detail-list {
        color: var(--ion-color-dark-contrast);
        font-size: 14px !important;
        font-weight: 500;
        line-height: 20px;
        display: flex !important;
        white-space: nowrap;
        box-sizing: content-box;
        border: 0px solid var(--ion-color-primary);
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.14);
        margin-right: 6px;
        padding: 8px;
      }
    }
    .series-detail-wrap {
      margin: 0;
        padding: 0;
        display: flex;
        margin-top: 10px;
        overflow: auto;
        .series-detail-list {
          color: rgba(255, 255, 255, 0.8);
          font-size: 12px !important;
          font-weight: 500;
          line-height: 20px;
          display: flex !important;
          white-space: nowrap;
          &:not(:first-child) {
            &:before {
              content: "•";
              margin-right: 5px;
              margin-left: 5px;
              color: var(--ion-color-white);
              font-size: 16px;
          }
        }
      }
    }
    .series-desc {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: var(--ion-color-dark-contrast);
      .series-story-header {
        width: 100%;
        margin-top: 6px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: var(--ion-color-white);
      }
      .series-story-description {
        width: 94%;
        margin-top: 12px;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 0 8px;
      }
    }
  }
  .episode-head-row {
    padding: 12px;
    .episodes {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: var(--ion-color-dark-contrast);
    }
    .select-episodes {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      border: 1px solid var(--ion-color-primary);
      border-radius: 4px;
      padding: 6px 12px;
      padding-right: 40px;
      color: var(--ion-color-dark-contrast);
      width: fit-content;
      margin-top: 8px;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        padding-right: 36px;
        margin-top: 12px;
      }
      cursor: pointer;
      &::after {
        content: "";
        border-top: 7px solid var(--ion-color-primary);
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        transition: block;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    .reveal-episodes {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 200;
      transform: scale(0);
      transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      &-list {
        li {
          font-size: 18px;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          &:not(:first-child) {
            margin-top: 20px;
          }
          &:hover, &.selected {
            transform: scale(1.3);
            font-weight: 500;
          }
        }
      }
      .close {
        opacity: 1;
        background-color: var(--ion-color-primary);
        width: 50px;
        height: 50px;
        position: absolute;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 80px;
        cursor: pointer;
        &:before, &:after {
          content: "";
          height: 20px;
          width: 2px;
          background-color: var(--ion-color-dark);
          position: absolute;
          z-index: 201;
        }
        &:before {
          content: "";
          transform: rotate(45deg);
        }
        &:after {
          content: "";
          transform: rotate(-45deg);
        }
      }
      &.true {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .cast-row {
    padding: 8px;
    margin-bottom: 12px;
    .cast-title {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--ion-color-dark-contrast);
    }
  }
}
