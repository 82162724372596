.searchresultpage{
  box-sizing: border-box;
  .no-items-found{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50%;
    flex-direction: column;
    color: var(--ion-color-white);
  }
  .searchbar-wrap{
    ion-icon{
      width: 28px;
      right: 20px;
      font-size: 30px;
    }
    .searchbar{
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      height: auto;
      padding: 10px 20px;
      border: 0px solid var(--ion-color-primary);
      transition: all 0.2s ease-in-out;
      border-radius: 8px;
      color: var(--ion-color-dark-contrast);
      background: rgba(255, 255, 255, 0.2);
      // &:focus {
      //   border-color: var(--ion-color-primary);
      // }
    }
    @media (max-width: 1025px){
      display: block;
    }
  }
  @media (max-width: 1025px){
    .searchcontainer{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .flex-column{
    box-sizing: border-box;
  }
  .flag{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h5{
    letter-spacing: 0;
    margin: 0;
    margin-top: 8px;
  }
}