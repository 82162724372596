.settingsContainer {
  box-sizing: border-box;
  .settings {
    .infoBlock {
      .infoBlockRow {
        padding: 14px;
        display: flex;
        align-items: center;
        background: var(--ion-color-dark-shade);
        // margin-bottom: 8px;
        margin-bottom: 4px;
        border-radius: 8px;
        .infoType {
          flex: unset;
          width: 100%;
          font-weight: 300;
          font-size: 14px;
          // font-size: 12px;
          line-height: 18px;
          color: var(--ion-color-white);
        }
        .infoValue {
          font-size: 14px;
          line-height: 18px;
          color: var(--ion-color-primary);
        }
        select {
          background-color: transparent;
          border: 0px solid var(--ion-color-primary);
          border-radius: 2px;
          padding-right: 12px;
          outline: none;
        }
      }
    }
    .Infoblockbox {
      box-sizing: inherit;
      border-radius: 10px;
      background: #1f1d33;

    }
    .InfoBlockSubTitle {
      font-size: 16px;
      color: var(--ion-color-white);
      padding-top: 20px;
      padding-bottom: 12px;
    }
  }
}
ion-toggle {
  // --background: #000;
  --background:#B8B8B8;
  --background-checked: var(--ion-color-primary);
  --handle-background:#ffffff;
  --handle-background-checked: var(--ion-color-secondary);
  padding: 4px;
  height: 12px;

}