.tabs-wrapper {
  position: fixed;
  bottom: 0;
  max-height: 64px;
  box-sizing: border-box;
  // background-color: var(--ion-color-dark);
  background-color: #0E0B24;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 5px #000;
  .tabs-label{
    font-size: 12px;
    padding-top: 2px;
    color: var(--ion-color-light);
  }
  ion-menu-button{
    height: 20px;
  }
  .ion-icon-button{
    position: relative;
    width: 100%;
    padding: 12px 6px;
    color: var(--ion-color-white);
    img{
      max-width: 20px;
    }
    ion-icon{
      color: var(--ion-color-light);
    }
    &.active{
      ion-icon, .tabs-label{
        color: var(--ion-color-primary);
        // background-color: var(--ion-color-primary);
      }
      img{
        // background-color: var(--ion-color-primary);
        // border-radius: 50%;
        fill: var(--ion-color-primary);
        // stroke: red;
      }
    }
    // background-position: center;
    // transition: background 0.6s;
    // &:first-child{
    //   padding-left: 12px;
    // }
    // &:last-child{
    //   padding-right: 12px;
    // }
    // &:hover{
    //   background: var(--ion-color-dark) radial-gradient(circle, transparent 1%, var(--ion-color-dark) 1%) center/15000%;
    // }
  }
  .clickeffect {
    position: relative;
    overflow: hidden;
    width: 100%;
    // .ripple {
    //   position: absolute;
    //   border-radius: 50%;
    //   transform: scale(0);
    //   animation: ripple 600ms linear;
    //   background-color: rgba(#fff, 0.2);
    // }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}