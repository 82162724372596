.profileContainer {
  min-height: 100%;
  display: flex;
  justify-content: center;
}
@import "../../../../../src/assets/css/themes/normal.scss";

.userProfile {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  width: 50%;
  padding: 16px;
  @media (max-width: 768px) {
    width: 100%;
  }
  .sb-avatar--text span {
    font-size: 28px;
  }
  .userProfileHeader {
    text-align: center;
    margin: 16px;
    margin-top: 0;
    h3 {
      font-size: 20px;
      color: var(--ion-color-secondary-contrast);
      margin-bottom: 12px;
    }
    img {
      margin-top: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #6a7f84;
    }
  }
  .infoValue1 {
    font-weight: 400;
    font-size: 24px;
    color: var(--ion-color-primary-contrast);
    margin-top: 12px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .infoValue2 {
    font-weight: 400;
    font-size: 14px;
    color: #bbbbbb;
    margin-top: 6px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .big-block-wrap {
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    .big-block {
      background: #1f1d33;
      width: 50%;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 16px 12px;
      .iconbackground {
        background: #2e3143;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 100%;
        }
      }
      .buttontext {
        font-size: 14px;
        line-height: 20px;
        color: var(--ion-color-white);
        margin-top: 12px;
      }
    }
  }
  .infoBlock {
    width: 100%;
    .infoBlockRow {
      padding: 14px 12px;
      border: 0px solid var(--ion-color-primary);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // background-color: var(--ion-color-dark-shade);
      background-color: #1f1d33;
      &:not(:first-child){
        margin-top: 16px;
      }
      .infoType,
      .infoValue {
        font-weight: 400;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .infoType {
        color: var(--ion-color-white);
      }
      .infoValue {
        color: var(--ion-color-primary);
        .btnprimary {
          margin: 0;
          height: 30px;
          min-width: 160px;
          // font-family: 'inter',sans-serif;
          font-size: 12px;
        }
        ion-button {
          font-weight: 700;
          text-transform: capitalize;
        }
      }
      .input-group{
        width: 40%;
      }
    }
  }
  a {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px;
    color: var(--ion-color-secondary);
  }
}

.cancel-popup {
  &-body {
    padding: 16px;
    counter-reset: section;
  }
  &-wrap {
    padding: 10px;
    .modal-wrapper {
      background-color: var(--ion-color-dark);
    }
    .logoimg {
      margin: 0 auto;
      max-width: 120px;
      img {
        width: 100%;
      }
    }
    .steps-wrapper {
      li {
        color: var(--ion-color-primary-contrast);
        font-size: 16px;
        line-height: 24px;
        &:not(:first-child) {
          margin-top: 8px;
        }
        span {
          color: var(--ion-color-primary);
          &::after {
            counter-increment: section;
            content: counter(section) ":";
          }
        }
      }
    }
    .note {
      color: var(--ion-color-primary-contrast);
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      display: block;
    }
  }
}