@import "../../../../assets/css/themes/normal.scss";

.filter-btn-wrap{
	padding: 8px 0;
	font-family: 'Inter', sans-serif;
	.filter-tabs{
		display: flex;
		overflow: auto;
		padding: 8px 16px;
		margin: 0;
		li{
			display: block;
			border-radius: 6px;
			text-align: center;
			padding: 8px 12px;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			white-space: nowrap;	
			background: var(--ion-color-dark-shade);
			cursor: pointer;
			transition: all 0.15s ease-in;
			&:not(:first-child){
				margin-left: 10px;
			}
			&:first-child{
				padding-left: 0px;
			}
			a{
				color: var(--ion-color-primary-contrast);
			}
			&.active{
				background-color: var(--ion-color-primary);
				color: var(--ion-color-secondary);
				a{
					color: var(--ion-color-black);
				}
			}
		}
	}
}