.watchHistory {
  h3 {
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 500;
    font-size: 20px;
    // color: var(--ion-color-primary-contrast);
    color: var(--ion-color-white);
    text-align: center;
    width: 100%;
  }
}

.img-wrapper {
  max-width: 100%;
  max-width: 360px !important;

  @media (max-width: 768px) {
    max-width: 100% !important;
    // max-height: 160px;
    overflow: hidden;
  }
  .historyimg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.watchHistoryList {
  display: flex;
  flex-wrap: wrap;
  // padding: 0 16px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  // .watchHistoryListItem {
  //   // margin-left: 20px;
  //   width: 30%;
  //   &:first-child{
  //     margin-left: 0;
  //   }
  // }
  .watchHistoryListItem{
    margin: 12px;
  }
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    // height: calc(100vh - 180px);
    .watchHistoryListItem {
      width: 100%;
      // max-width: 360px;
      margin: 0;
      
    }
  }

  .card-inside-wrap {
    display: flex;
    flex-direction: column;
    border: 0px solid var(--ion-color-primary);
    border-radius: 8px;
    overflow: hidden;
  }
  ion-card {
    margin-left: 0;
    margin-right: 0;
    border-radius: 4px;
    background-color:var(--ion-color-dark-shade);
  }
  ion-card-subtitle {
    color: var(--ion-color-primary-contrast);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  ion-card-header {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .moviedetail {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--ion-color-dark-contrast);
    margin-bottom: 12px;
  }
  .lastseen {
    color: var(--ion-color-dark-contrast);
  }
  .seencount {
    margin-bottom: 12px;
    color: var(--ion-color-dark-contrast);
    font-size: 13px;
  }
}
.watchHistoryListItemBtnGroup {
  display: flex;
  button {
    border: 0px solid var(--ion-color-secondary);
    background-color: var(--ion-color-secondary);
    border-radius: 4px;
    padding: 8px 10px;
    // margin-left: 12px;
    font-size: 12px;
    line-height: 18px;
    margin-right: 6px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    color: var(--ion-color-white);
    font-weight: 500;
    @media (max-width: 768px) {
      padding: 6px 8px;
      font-size: 12px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);
    }
  }
}
