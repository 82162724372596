.radial-play {
  position: absolute;
  bottom: 16px;
  right: 4px;
  &:after {
    content: "";
    border-left: 10px solid #fff;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  svg{
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
  }
}